@import "variable";
@import "common";
@import "layout";
@import "reserve";
@import "guest";
@import "about";
@import "admin";
@import "config";

.pageContainer{
    margin: 0 auto;;
}
.pageContainer,
.userPage,
.adminPage{
    width:100%;
    max-width:700px;
}
.userPage{
    margin:1rem auto;
}