.aboutPage{
    background-color: #fff;
    font-size: 1.2rem;
    
    h1{
        font-weight: 800;
    }
    strong{
        font-weight: 700;
    }
    
    video {
        width: 100%;
        max-width: 570px;
    }
    .shipName{
        font-size: 3.5rem;
        font-weight: bold;
        font-family: "YEONGJUSeonbiTTF";
    }
    
    .boxContainer{
        color:#333;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap:0.5rem;
        text-align: center;
    }
    .pricing{
        .box{
            .desc{
                font-size: 1rem;
            }
        }
    }
    .pageContainer{
        margin:0 auto;
    }

    .mapContainer{
        overflow: hidden;
        .map{
            margin:0 auto;
        }
    }

    .seasonContainer{
        .bar{
            background-color: $primary;
            border-radius: 0.5rem;
            color:#fff;
            width:30%;
            font-size:0.9rem;
            font-weight: bold;
        }

        .season{
            margin-top:1rem;    
            
            &.season2{
                .bar{
                    background-color: #fdce7e;
                    background-color: #ffecb9;
                    width:40%;
                    margin-left:30%;
                    color:#333;
                }
            }

            &.season3{
                .bar{
                    background-color: #cde59b;
                    width:60%;
                    margin-left:40%;
                    color:#333;
                }
            }
        }
    }
}