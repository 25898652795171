.reserveState{
    &.COMPLETE{
        color:$primary;
        font-weight: bold;
    }
    &.CANCEL{
        color:$danger;
    }
    &.CLOSE{
        color:#D37676;
    }
}// .reserveState

.monthNav{
    background-color: #fff;
    border:1px solid #e5e5e5;
    border-radius: 0.5rem;
    margin:1rem 0.5rem;
    overflow: hidden;
    .nav-item{
        .nav-link{
            color:#868b94;
            // border-bottom: 1px solid #ddd;
            border-right:1px solid #e5e5e5;
            color:#999;
            strong{
                color:#999;
                font-weight: 400;
            }
        
            :last-child{
                border:none;
            }
            
            &.active{
                background-color: transparent;
                background-color: $primary;
                color:#eee;
                strong{
                    color:#fff;
                    font-weight: 700;
                }
            }
        }
    }
}

.weekNav{
    background-color: #fff;
    border:1px solid #e5e5e5;
    border-radius: 0.5rem;
    margin:1rem 0.5rem;
    overflow: hidden;
    .nav-item{
        .nav-link{
            color:#999;
            border-right:1px solid #e5e5e5;
            :last-child{
                border:none;
            }
            
            &.week7{
                color:$danger;
            }
            &.week6{
                color:$primary;
            }
            

            &.active{
                background-color: $yellow;
                color:#333;
            }
        }//.nav-link
    }//.nav-item
}//.weekNav

.reserveListPage{
    // position: relative;
    min-height: 20rem;
    
    // overflow: hidden;
    &.admin{
        .reserveList{
            .reserve{
                // min-height: 180px;
                .guestCount{
                    strong{
                        color:#666;
                    }
                }
            }
        }
    }
    .reserveList{
        text-align: center;
        .reserve{
            width:150px;
            min-height:155px;
            margin:0.5rem;
            background-color: #fff;
            box-shadow: 1px 1px 0.25rem rgba(0, 0, 0, 0.15);
            overflow: hidden;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            
            a,
            a:link{
                text-decoration: none;
                .day{
                    color:#333;
                }
            }

            .remaining{
                color:#777;
                strong{
                    color:$primary;
                }
            }

            .btn{
                font-size: 1rem;
                padding:0.6rem  0.25rem !important;
                // position: absolute;
                width:100%;
                // bottom: 0;
            }
            
            .date{
                .day{
                    font-size: 1.7rem;
                    .week{
                        font-size: 0.8rem;;
                    }
                }
            }
            .btn{
                background-color: $primary;
                border:none;
            }

            .memo{
                font-size: 1rem;
                font-style: italic;
                color:#333;
            }

            .price{
                color:$secondary;
                strong{
                    color:#666;
                }
            }

            &.CANCEL,
            &.CLOSE,
            &.COMPLETE{
                background-color: rgba(255, 255, 255, 0.5) !important;
                .date{
                    opacity: 0.5;
                }
                .btn{
                    opacity: 0.5;
                }
            }
            
        }

        .date{
            .week{
                color:#999;
                &.week7{
                    color:$danger;
                }
                &.week6{
                    color:$primary;
                }
            }
        }
    }

}// .reserveListPage

.reserveComplete{
    .guestInfo{
        margin:3rem auto ;
        color:#666;
        strong{
            font-weight: 800;
            color:#555;
        }
    }
    .guideList{
        // padding:10px;
        .title{
            color:$primary;
        }
        ul{
            border:1px solid #eee;
            // border-radius: 0.5rem;
            // background-color: #fffee0;
        }
        li{
            background-color: transparent;
            border:none;
            padding:10px 7px;
            // background-color: $gray-100;
        }
        
    }
    
}


.reserveFormPage{
    // .col-form-label{
    //     font-weight: bold;
    // }
    .title{
        .date{
            color:$primary;
        }
    }
    .guestButtons{
        display: flex;   
        flex-wrap: wrap;
        column-gap: 0.2rem;
        row-gap: 0.2rem;
        // overflow-y: hidden;
        .btn{
            width: 42px;
        }
    }

    .reserveGuide{
        ul{
            list-style: square;
            font-size: .9rem;
            padding:1rem 1rem 1rem 2rem;;
            li{
                margin-top:0.2rem;
            }
        }
    }

    
}

@media screen and ( max-width:600px ){
    .reserveListPage{
        .reserveList{
            .reserve{
                width:45%;
                margin:1rem 0.5rem;
            }
        }
    }   

    .reserveFormPage{
        .guestButtons{
            flex-wrap: nowrap;
            overflow-x: auto;
        }
    }
}