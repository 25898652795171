html,
body,
#root,
.App {
  height: 100% !important;
}

.contentBody {
  background-color: #f1f1f1;
  // min-height: 1rem;
  >div{
    padding-bottom: 3rem !important;
  }
}

.header {
  padding-bottom: 0;
  .headerInner{
    margin-top:2rem;
  }

  .headerBody {
    width: 100%;
    z-index: 100;
    * {
      z-index: 100;
    }
  }
  .title {
    font-family: "YEONGJUSeonbiTTF";
    margin: 0 auto;
    font-size: 2rem;
  }
  .logo {
    max-height: 3rem;
    margin-right: 1rem;
  }
  a {
    color: #333;
  }
}

.gnb {
  .nav-link {
    background-color: #fff;
    color: #333;
    font-size: 1.3rem;
    border: none;
    border-bottom: 2px solid #fff;
    &.active {
      color: $primary;
      font-weight: bold;
      color: #000;
      border-bottom: 2px solid #000;
    }
  }

  &.admin {
    .nav-link {
      background-color: #f9f9f9;
      border-bottom: 1px solid #ccc;
      font-size: 1.1rem;
      &.active {
        background-color: #fff;
        border: 1px solid $primary;
        border-bottom: none;
      }
    }
  }
} //.gnb

.footer {
  background-color: #fff;
  padding:0.5rem;
  .business {
	span{
		&::after{
			font-family: 'Noto Sans KR', sans-serif;
			content:"|";
			font-weight: normal;
			color:#ccc;
			margin:0.2rem;
		}
		&:last-child{
			&::after{
				content:none;
			}
		}
	}
  }
  .title {
    font-family: "YEONGJUSeonbiTTF";
  }
  a {
    color: #333;
  }
}

.paging {
  margin-top: 60px;
  text-align: center;
  ul {
    li {
      display: inline-block;
      padding: 0 5px;

      &.on {
        button {
          background: #2faeff;
          border-radius: 50%;
          color: #fff;
        }
      }
      button {
        min-width: 25px;
        height: 25px;
        line-height: 25px;
        font-size: 14px;
        display: inline-block;
        min-width: 30px;
        height: 30px;
        line-height: 30px;
        &:hover {
          text-decoration: underline;
        }
      }
    } //end li
  } //end ul
}
