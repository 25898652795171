@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
@font-face {
  font-family: "YEONGJUSeonbiTTF";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/2403@1.0/YEONGJUSeonbiTTF.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}

.notosanskr * {
  font-family: "Noto Sans KR", sans-serif;
}

body {
  background-color: #fff;
  font-family: "Noto Sans KR", sans-serif;
}

strong {
  font-weight: 900;
  color: #000;
}

button {
  border: none;
  background-color: transparent;
}

a {
  &:link,
  &:visited {
    text-decoration: none !important;
    // color:#333;
  }
}

.btn {
  &.btn-outline-primary {
    border-color: $primary;
  }
  &.btn-primary {
    background-color: $primary !important;
    color: #fff;
  }

  &.btn-green {
    color: #fff;
    background-color: #60ca44;
  }

  &.btn-contact {
    color: 333;
    border-radius: 1rem;
    padding: 3px 10px;
    font-size: 0.7rem;
    margin-left: 0.7rem;
  }
}

.loading {
  width: 100% !important;
  clear: both !important;
  text-align: center;

  &.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.2);
    transition: opacity 0.25s;
    opacity: 0;
    &.on {
      opacity: 1;
    }
    &.off {
      transition: opacity 0.5s;
      opacity: 0;
    }
  }

  > img {
    width: 100px;
  }
  .text {
    font-size: 14px;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: 0.15em; /* Adjust as needed */
    animation: typing 1s steps(40, end) infinite;
  }
}

.mt-6 {
  margin-top: 6rem !important;
}
.mt-7 {
  margin-top: 7rem !important;
}
.mt-10 {
  margin-top: 10rem !important;
}
