.loginPage{
    max-width: 340px;
    margin:3rem auto;
    padding: 1.3rem;
    border:1px solid #f1f1f1;
    background-color: #fff;
}

.adminSummary{
    border:1px solid #ddd;
    .col{
        padding:1rem 0;
        font-size: 1.5rem;
    }
}