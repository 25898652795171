.guestState{
    font-size: 0.9rem;
    font-style: italic;
    color:#fff;
    &.COMPLETE{
        background-color: $success;
        font-weight: bold;
    }
    &.CANCEL{
        background-color: $danger;
    }
    &.WATTING{
        background-color: $warning;
    }
}// .guestState

.adminGuestPage{
    .search{
        .btn-check{
            &:checked+.btn{
                background-color: #fff;
                border:1px solid #ccc;
            }
        }
    }
}

// 예약자 목록 (공통되는)
.guestList{
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(160px, auto));
    gap:1rem;
    align-content: center;
    justify-items: center;
    align-items: start;
    justify-content: center;
    .guest{
        text-align: center;
        line-height: 1.7rem;
        .guestState{
            opacity: 0.8;
        }
        .date{
            font-size: 0.9rem;
            &.createdAt{
                color:#777;
            }
        }
        .control{
            display: grid;
            grid-template-columns:repeat(2, 1fr);
            column-gap: 5px;
            justify-content: center;
            row-gap: 5px;
        }
        &.CANCEL,
        &.DELETE{
            opacity: 0.65;
            .guestInfo,
            .createdAt{
                text-decoration: line-through;
            }
        }
        a{
            color:#333;
        }
    }
}
